

    import { Component, Vue } from 'vue-property-decorator';
    import { onAuthStateChanged, UserInfo } from 'firebase/auth';
    import { auth } from '@/firebase';

    @Component
    export default class App extends Vue {

        authUser: UserInfo | null = null;

        created() {
            onAuthStateChanged(auth, (user) => {
                this.authUser = user;
            });
        }


        mounted() {
            // @ts-ignore
            $('.sidenav').sidenav();
        }

        logoutUser() {
            auth.signOut();
        }

    }

